export default [
    {
        name: "customerShippingPrice",
        path: "/bill/quote/customer-shipping-price",
        component: () =>
            import(
                /* webpackChunkName: "customerShippingPrice" */ "@/views/bill/quote/customer-shipping-price/list.vue"
            ),
        meta: { title: "发货价格(客户)", keepAlive: true },
    },
    {
        name: "customerShippingPriceAdd",
        path: "/bill/quote/customer-shipping-price/add",
        component: () =>
            import(
                /* webpackChunkName: "customerShippingPrice" */ "@/views/bill/quote/customer-shipping-price/add.vue"
            ),
        meta: { title: "发货价格新增(客户)" },
    },
    {
        name: "customerShippingPriceEdit",
        path: "/bill/quote/customer-shipping-price/edit",
        component: () =>
            import(
                /* webpackChunkName: "customerShippingPrice" */ "@/views/bill/quote/customer-shipping-price/edit.vue"
            ),
        meta: { title: "发货价格修改(客户)", keepAlive: false },
    },
    {
        name: "logPage",
        path: "/bill/quote/customer-shipping-price/log",
        component: () =>
            import(
                /* webpackChunkName: "customerShippingPrice" */ "@/views/bill/quote/customer-shipping-price/log.vue"
            ),
        meta: { title: "报价日志", keepAlive: false },
    },
    {
        name: "salesShippingPrice",
        path: "/bill/quote/sales-shipping-price",
        component: () =>
            import(/* webpackChunkName: "salesShippingPrice" */ "@/views/bill/quote/sales-shipping-price/list.vue"),
        meta: { title: "发货价格(业务员)", keepAlive: true },
    },
    {
        name: "salesShippingPriceAdd",
        path: "/bill/quote/sales-shipping-price/add",
        component: () =>
            import(/* webpackChunkName: "salesShippingPrice" */ "@/views/bill/quote/sales-shipping-price/add.vue"),
        meta: { title: "发货价格新增(业务员)" },
    },
    {
        name: "salesShippingPriceEdit",
        path: "/bill/quote/sales-shipping-price/edit",
        component: () =>
            import(/* webpackChunkName: "salesShippingPrice" */ "@/views/bill/quote/sales-shipping-price/edit.vue"),
        meta: { title: "发货价格修改(业务员)", keepAlive: false },
    },
    {
        name: "salesDispatchPrice",
        path: "/bill/quote/sales-dispatch-price",
        component: () =>
            import(/* webpackChunkName: "salesDispatchPrice" */ "@/views/bill/quote/sales-dispatch-price/list.vue"),
        meta: { title: "派送价格" },
    },
    {
        name: "salesDispatchPriceAdd",
        path: "/bill/quote/sales-dispatch-price/add",
        component: () =>
            import(/* webpackChunkName: "salesDispatchPrice" */ "@/views/bill/quote/sales-dispatch-price/add.vue"),
        meta: { title: "派送价格添加" },
    },
    {
        name: "salesDispatchPriceEdit",
        path: "/bill/quote/sales-dispatch-price/edit",
        component: () =>
            import(/* webpackChunkName: "salesDispatchPrice" */ "@/views/bill/quote/sales-dispatch-price/edit.vue"),
        meta: { title: "派送价格修改" },
    },
    {
        name: "salesDispatchPriceCopy",
        path: "/bill/quote/sales-dispatch-price/copy",
        component: () =>
            import(/* webpackChunkName: "salesDispatchPrice" */ "@/views/bill/quote/sales-dispatch-price/copy.vue"),
        meta: { title: "派送价格复制" },
    },
    {
        name: "peakSeasonFee",
        path: "/bill/quote/peak-season-fee",
        component: () => import(/* webpackChunkName: "peakSeasonFee" */ "@/views/bill/quote/peak-season-fee/list.vue"),
        meta: { title: "旺季加收费" },
    },
    {
        name: "peakSeasonFeeAdd",
        path: "/bill/quote/peak-season-fee/add",
        component: () => import(/* webpackChunkName: "peakSeasonFee" */ "@/views/bill/quote/peak-season-fee/add.vue"),
        meta: { title: "旺季加收费新增" },
    },
    {
        name: "peakSeasonFeeEdit",
        path: "/bill/quote/peak-season-fee/edit/:id",
        component: () => import(/* webpackChunkName: "peakSeasonFee" */ "@/views/bill/quote/peak-season-fee/edit.vue"),
        meta: { title: "旺季加收费编辑" },
    },
    {
        name: "peakSeasonFeeCopy",
        path: "/bill/quote/peak-season-fee/copy",
        component: () => import(/* webpackChunkName: "peakSeasonFee" */ "@/views/bill/quote/peak-season-fee/copy.vue"),
        meta: { title: "旺季加收费复制" },
    },
    {
        name: "faceSalesPrice",
        path: "/bill/quote/face-sales-price",
        component: () => import(/* webpackChunkName: "faceSalesPrice" */ "@/views/bill/quote/face-sales-price.vue"),
        meta: { title: "面单销售价格" },
    },

    {
        name: "faceSalesPriceContract",
        path: "/bill/quote/face-sales-price-contract",
        component: () =>
            import(/* webpackChunkName: "faceSalesPrice" */ "@/views/bill/quote/face-sales-price-contract.vue"),
        meta: { title: "面单销售价格-承包区" },
    },
    {
        name: "customProvince",
        path: "/bill/quote/custom-province",
        component: () => import(/* webpackChunkName: "customProvince" */ "@/views/bill/quote/custom-province.vue"),
        meta: { title: "省份自定义", target: "_blank" },
    },
];
