export default [
    {
        name: "grossProfit",
        path: "/report-form/gross-profit",
        component: () => import(/* webpackChunkName: "gross-profit" */ "@/views/report-form/gross-profit/index.vue"),
        meta: { title: "毛利分析报表", target: "_blank" },
    },
    {
        name: "grossProfitSum",
        path: "/report-form/gross-profit/sum",
        component: () => import(/* webpackChunkName: "gross-profit" */ "@/views/report-form/gross-profit/sum.vue"),
        meta: { title: "毛利分析明细" },
    },
    {
        name: "singleProfit",
        path: "/report-form/single-profit",
        component: () => import(/* webpackChunkName: "single-profit" */ "@/views/report-form/single-profit.vue"),
        meta: { title: "单票利润报表", target: "_blank" },
    },
    {
        name: "operatingProfit",
        path: "/report-form/operating-profit",
        component: () => import(/* webpackChunkName: "report-summary" */ "@/views/report-form/operating-profit.vue"),
        meta: { title: "网点经营利润报表" },
    },
    {
        name: "weightStageDot",
        path: "/report-form/weightStageDot",
        component: () => import(/* webpackChunkName: "weightStageDot" */ "@/views/report-form/weightStageDot.vue"),
        meta: { title: "重量阶段表（网点）", target: "_blank" },
    },
    {
        name: "weightStage",
        path: "/report-form/weightStage",
        component: () => import(/* webpackChunkName: "weightStage" */ "@/views/report-form/weightStage.vue"),
        meta: { title: "重量阶段表（客户）", target: "_blank" },
    },
    {
        name: "weightStageContract",
        path: "/report-form/weightStageContract",
        component: () => import(/* webpackChunkName: "weightStage" */ "@/views/report-form/weightStageContract.vue"),
        meta: { title: "重量阶段表（承包区）", target: "_blank" },
    },
    {
        name: "deliverGoods",
        path: "/report-form/deliver-goods",
        component: () => import(/* webpackChunkName: "deliverGoods" */ "@/views/report-form/deliver-goods/index.vue"),
        meta: { title: "发货统计报表", target: "_blank" },
    },
    {
        name: "deliverGoodsSum",
        path: "/report-form/deliver-goods/sum",
        component: () => import(/* webpackChunkName: "deliverGoodsSum" */ "@/views/report-form/deliver-goods/sum.vue"),
        meta: { title: "发货统计明细" },
    },
    // 资金流水管理
    {
        name: "reportFlow",
        path: "/report-form/flow",
        component: () => import(/* webpackChunkName: "report-flow" */ "@/views/report-form/capital/flow.vue"),
        meta: { title: "资金流水", target: "_blank" },
    },
    {
        name: "reportBalance",
        path: "/report-form/flow/balance",
        component: () => import(/* webpackChunkName: "report-balance" */ "@/views/report-form/capital/balance.vue"),
        meta: { title: "账户余额" },
    },
    {
        name: "reportExpenseType",
        path: "/report-form/flow/expense-type",
        component: () => import(/* webpackChunkName: "report-flow" */ "@/views/report-form/capital/expense-type.vue"),
        meta: { title: "科目维护" },
    },
    {
        name: "flowSummary",
        path: "/report-form/flow/summary",
        component: () => import(/* webpackChunkName: "report-flow" */ "@/views/report-form/capital/sum.vue"),
        meta: { title: "资金汇总" },
    },
    {
        name: "reportSummary",
        path: "/report-form/summary",
        component: () => import(/* webpackChunkName: "report-summary" */ "@/views/report-form/summary.vue"),
        meta: { title: "营业总表", target: "_blank" },
    },

    // 客户记账管理
    {
        name: "customerBilling",
        path: "/report-form/customerBilling",
        component: () =>
            import(/* webpackChunkName: "report-summary" */ "@/views/report-form/customer-billing/index.vue"),
        meta: { title: "客户记账", target: "_blank" },
    },
    {
        name: "customerBillingSummary",
        path: "/report-form/customerBilling/summary",
        component: () =>
            import(/* webpackChunkName: "report-summary" */ "@/views/report-form/customer-billing/summary.vue"),
        meta: { title: "记账汇总" },
    },
    {
        name: "customerBillingBalance",
        path: "/report-form/customerBilling/balance",
        component: () =>
            import(/* webpackChunkName: "report-summary" */ "@/views/report-form/customer-billing/balance.vue"),
        meta: { title: "记账余额" },
    },
    {
        name: "customerBillingSubject",
        path: "/report-form/customerBilling/subject",
        component: () =>
            import(/* webpackChunkName: "report-summary" */ "@/views/report-form/customer-billing/subject.vue"),
        meta: { title: "记账科目维护" },
    },
];
